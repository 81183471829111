@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/helvetica-255");
/* @import url("https://fonts.cdnfonts.com/css/bank-gothic"); */


* {
  font-family: Helvetica;
  /* background-color: black; */
}


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica', 'Ubuntu', 'Segoe UI', 'Roboto', 'Oxygen',
     'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
